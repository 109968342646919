import React from 'react';
import { BlogLayout } from '../components/Blog';
import BlogPost from '../components/Blog/BlogPost';
import { graphql } from "gatsby"
import { Container, Grid, Item } from '../components/Grid';
import SEO from '../components/SEO';
import Reveal from '../components/Reveal';
import { Fade } from 'react-reveal';



export default ({data}) => {
  const blogPosts = data.allMdx.edges;
  const featured = undefined;

  return (
    <BlogLayout>
      <SEO title="Blog"/>
      <Container>
          <div className="my-20 md:my-40">
              <div className="relative max-w-3xl">
                  <Reveal duration={1250} className="mb-16">
                      <h1 className="text-xl sm:text-2xl md:text-3xl font-regular">
                          Nobody ever figures out what life is all about, and it doesn't matter.
                          Explore the world. Nearly everything is really interesting if you go into it deeply enough.
                      </h1>
                  </Reveal>
                  <p className="text-lg opacity-75"><span className="text-brand font-bold">-</span> Richard P. Feynman</p>
              </div>
          </div>

      </Container>
      <Fade duration={1500} delay={1250}>
      <div className="bg-default bg-default-dots text-dark py-40">
          <Container>
            <div className="mb-20">
                <Reveal bgClass="bg-default">
                    <h1 className="font-bold text-4xl">Latest posts</h1>
                </Reveal>
            </div>

            <Grid>
              {
                blogPosts.map( (el, i) => {
                  return (
                    <Item key={i} sm="1/2" lg="1/3">
                        <BlogPost animated { ...el.node.frontmatter }/>
                    </Item>
                  )})
              }
              </Grid>
          </Container>
      </div>
      </Fade>
    </BlogLayout>
  );
}


export const query = graphql`
query {
  allMdx(sort: {fields: [frontmatter___date], order: DESC}) {
    edges {
      node {
        frontmatter {
          title
          path
          tags
          description
          date(formatString: "MMMM DD, YYYY")
          featured
          image {
            childImageSharp {
              fluid(maxWidth: 460) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
        }
      }
    }
  }
}
`
